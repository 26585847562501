<template>
    <div>
      <div class="row">
        <div class="col-md-12">
          <KTPortlet v-bind:title="title">
            <template v-slot:body>
              <v-form ref="form" @submit.prevent="submitForm">
                <div class="row">
                  <div class="col-6">
                    <v-text-field
                      ref="searchKeyword"
                      v-model="form.searchKeyword"
                      :disabled="datatable.loading"
                      :label="$t('SHARED.KEYWORD')"
                      :hint="$t('PDPA.SEARCH.KEYWORD_HINT')"
                      prepend-icon="mdi-file-document-box-search-outline"
                    ></v-text-field>
                  </div>

                  <div class="col-3">
                    <v-autocomplete
                      v-model="form.termAndPrivacy"
                      :disabled="datatable.loading"
                      :items="form.termAndPrivacyItems"
                      :loading="form.termAndPrivacyLoading"
                      :search-input.sync="form.termAndPrivacySearch"
                      hide-no-data
                      hide-selected
                      item-text="text"
                      item-value="value"
                      :label="$t('PDPA.SEARCH.TERM_AND_PRIVACY')"
                      :placeholder="
                        $t('SHARED.START_TYPING_TO_SEARCH')
                      "
                      prepend-icon="mdi-database-search"
                      return-object
                      clearable
                    ></v-autocomplete>
                  </div>

                  <div class="col-3">
                    <v-autocomplete
                      v-model="form.typeTermAndPrivacy"
                      :disabled="datatable.loading"
                      :items="form.typeTermAndPrivacyItems"
                      :loading="form.typeTermAndPrivacyLoading"
                      :search-input.sync="form.typeTermAndPrivacySearch"
                      hide-no-data
                      hide-selected
                      item-text="text"
                      item-value="value"
                      :label="$t('PDPA.SEARCH.TYPE_TERM_AND_PRIVACY')"
                      :placeholder="
                        $t('SHARED.START_TYPING_TO_SEARCH')
                      "
                      prepend-icon="mdi-database-search"
                      return-object
                      clearable
                    ></v-autocomplete>
                  </div>

                </div>
                <div class="row">
                  <div class="col-12">
                    <v-btn
                      :disabled="datatable.loading"
                      color="success"
                      class="mr-4"
                      tile
                      type="submit"
                    >
                      <v-icon v-if="!datatable.loading" left
                        >mdi-database-search</v-icon
                      >
                      <v-icon v-if="datatable.loading" left
                        >fa fa-spinner fa-spin</v-icon
                      >
                      {{ $t("SHARED.SEARCH_BUTTON") }}
                    </v-btn>
                    <v-btn
                      :disabled="datatable.loading"
                      color="default"
                      class="mr-4"
                      type="reset"
                      tile
                      @click.prevent="resetForm"
                    >
                      <v-icon left>mdi-eraser</v-icon>
                      {{ $t("SHARED.RESET_BUTTON") }}
                    </v-btn>
                    <v-btn
                      :disabled="datatable.loading"
                      color="warning"
                      class="mr-4"
                      tile
                      @click.prevent="Export"
                    >
                      <v-icon v-if="!datatable.loading" left
                        >fas fa-file-export</v-icon
                      >
                      <v-icon v-if="datatable.loading" left
                        >fa fa-spinner fa-spin</v-icon
                      >
                      {{ $t("SHARED.EXPORT_BUTTON") }}
                    </v-btn>
                  </div>
                </div>
              </v-form>
  
              <v-divider class="my-4"></v-divider>
  
              <v-subheader>
                <v-icon left>mdi-table-search</v-icon>
                {{ $t("SHARED.SEARCH_RESULT") }}
                <v-spacer></v-spacer>
              </v-subheader>
  
              <v-data-table
                :headers="datatable.headers"
                :items="datatable.items"
                :loading="datatable.loading"
                :options.sync="datatable.options"
                :server-items-length="datatable.total"
                :footer-props="{
                  'items-per-page-options': [30, 50, 100],
                }"
                multi-sort
              >
              <template v-slot:item.inActiveStatus="{ item }">
                  <v-icon 
                    :class="getInActiveStatus(item.inActiveStatus)">
                    {{ getInActiveStatusIcon(item.inActiveStatus) }}
                  </v-icon>
              </template>
              <template v-slot:item.termAndPrivacyApprove="{ item }">
                  <v-icon 
                    :class="getInActiveStatus(!item.termAndPrivacyApprove)">
                    {{ getInActiveStatusIcon(!item.termAndPrivacyApprove) }}
                  </v-icon>
              </template>
            </v-data-table>
  
              <v-dialog v-model="datatable.loading" persistent width="300">
                <v-card>
                  <v-card-title class="headline">{{
                    $t("SHARED.PLEASE_WAIT")
                  }}</v-card-title>
                  <v-card-text>
                    <p>{{ $t("SHARED.PROCESSING") }}</p>
                    <v-progress-linear
                      indeterminate
                      color="amber lighten-3"
                      class="mb-3"
                    ></v-progress-linear>
                  </v-card-text>
                </v-card>
              </v-dialog>
            </template>
          </KTPortlet>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import ApiService from "@/common/api.service";
  import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
  import KTPortlet from "@/views/partials/content/Portlet.vue";
  import JwtService from "@/common/jwt.service";
  
  export default {
    components: {
      KTPortlet,
    },
    data() {
      return {
        form: {
          searchKeyword: "",
          dialog: false,
          loading: false,
  
          termAndPrivacy: null,
          termAndPrivacySearch: "",
          termAndPrivacyItems: [],
          termAndPrivacyLoading: false,

          typeTermAndPrivacy: null,
          typeTermAndPrivacySearch: "",
          typeTermAndPrivacyItems: [
            {
              text: this.$t("PDPA.SEARCH.TERMANDPRIVACY_TYPE_TERM"),
              value: "USER_TERMANDPRIVACY_TYPE_TERM",
            },
            {
              text: this.$t("PDPA.SEARCH.TERMANDPRIVACY_TYPE_PRIVACY"),
              value: "USER_TERMANDPRIVACY_TYPE_PRIVACY",
            },
          ],
          typeTermAndPrivacyLoading: false,
        },
        datatable: {
          headers: [
            {
              text: this.$t("PDPA.SEARCH.USER_NAME"),
              value: "userName",
              align: "center",
            },
            {
              text: this.$t("PDPA.SEARCH.EMAIL"),
              value: "email",
              align: "center",
            },
  
            {
              text: this.$t("PDPA.SEARCH.FULL_NAME"),
              value: "fullName",
              align: "center",
            },
            {
              text: this.$t("PDPA.SEARCH.PHONE_NUMBER"),
              value: "phoneNumber",
              align: "center",
            },
            {
              text: this.$t("PDPA.SEARCH.IN_ACTIVE_STATUS"),
              value: "inActiveStatus",
              align: "center",
            },
            {
              text: this.$t("PDPA.SEARCH.TITLE"),
              value: "title",
              align: "center",
            },
            {
              text: this.$t("PDPA.SEARCH.TYPE"),
              value: "userTermAndPrivacyTypeCode",
              align: "center",
            },
            {
              text: this.$t("PDPA.SEARCH.APPROVE"),
              value: "termAndPrivacyApprove",
              align: "center",
            }
          ],
          items: [],
          total: 0,
          loading: true,
          options: {
            sortBy: ["userName"],
            sortDesc: [true],
            itemsPerPage: 30,
          },
        },
      };
    },
    methods: {
      submitForm() {
        this.getDataFromApi().then((data) => {
          this.datatable.total = data.total;
          this.datatable.items = data.items;
        });
      },
      resetForm() {
        this.form.searchKeyword = "";
        this.form.termAndPrivacy = null;
        this.form.typeTermAndPrivacy = null;
        this.submitForm();
      },
      getDataFromApi() {
        this.datatable.loading = true;
        return new Promise((resolve) => {
          ApiService.setHeader();
          ApiService.post("/Api/PersonalDataProtectAct/Search", {
            GroupBy: this.datatable.options.groupBy,
            GroupDesc: this.datatable.options.groupDesc,
            ItemsPerPage: this.datatable.options.itemsPerPage,
            Page: this.datatable.options.page,
            SortBy: this.datatable.options.sortBy,
            SortDesc: this.datatable.options.sortDesc,
            SearchKeyword: this.form.searchKeyword,
            TermAndPrivacyId: this.form.termAndPrivacy
              ? parseInt(this.form.termAndPrivacy.value)
              : null,
            TermAndPrivacyType : this.form.typeTermAndPrivacy
              ? this.form.typeTermAndPrivacy.value
              : null,
          })
            .then(({ data }) => {
              resolve({
                items: data.items,
                total: data.total,
              });
            })
            .finally(() => {
              this.datatable.loading = false;
            });
        });
      },
      getInActiveStatus(inActiveStatus){
        if (!inActiveStatus) 
          return 'text-success';
        else 
          return 'text-danger';
      },
      getInActiveStatusIcon(inActiveStatus){
        if (!inActiveStatus) 
          return 'check_circle';
        else 
          return 'cancel';
      },
      getTermAndPrivacyFromApi() {
        return new Promise((resolve) => {
          ApiService.setHeader();
          ApiService.post("/Api/PersonalDataProtectAct/TermAndPrivacy/Options", {
            query: this.form.roleSearch,
          })
            .then(({ data }) => {
              resolve(data);
            })
            .finally(() => {
              this.form.roleLoading = false;
            });
        });
      },
      Export() {
        var TermAndPrivacyId = this.form.termAndPrivacy
          ? parseInt(this.form.termAndPrivacy.value)
          : "";
        var TermAndPrivacyType = this.form.typeTermAndPrivacy
          ? this.form.typeTermAndPrivacy.value
          : "";
        var SortBy = "";
        var SortDesc = "";

        this.datatable.options.sortBy.forEach((val) => {
          SortBy += '&SortBy='+val;
        });
        this.datatable.options.sortDesc.forEach((val) => {
          SortDesc += '&SortDesc='+val;
        });
        window.open(
          "/Files/Export/PersonalDataProtectAct?searchKeyword=" +
            this.form.searchKeyword +
            "&TermAndPrivacyId=" + 
            TermAndPrivacyId +
            "&TermAndPrivacyType=" + 
            TermAndPrivacyType +
            SortBy + SortDesc +
            "&token=" +
            this.token,
          "_blank"
        );
      },
    },
    mounted() {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: this.$t("MENU.PDPA.PDPA_LIST"), route: "/PDPA" },
        { title: this.$t("MENU.PDPA.SEARCH") },
      ]);
    },
    computed: {
      title() {
        return this.$t("MENU.PDPA.SEARCH");
      },
      token() {
        return JwtService.getToken();
      },
    },
    watch: {
      "datatable.options": {
        handler() {
          if (!this.form.startDate) {
            var s = new Date();
            s.setDate(1);
            this.form.startDate = s.toISOString().substr(0, 10);
          }
  
          if (!this.form.endDate) {
            var e = new Date();
            var year = new Date().getFullYear();
            var month = new Date().getMonth();
            month = month == 12 ? 0 : month + 1;
            var day = new Date(year, month, 0).getDate();
            e.setDate(day);
            this.form.endDate = e.toISOString().substr(0, 10);
          }
  
          this.getDataFromApi().then((data) => {
            this.datatable.total = data.total;
            this.datatable.items = data.items;
          });
        },
        deep: true,
      },
      "form.termAndPrivacySearch": {
        handler() {
          this.getTermAndPrivacyFromApi().then((data) => {
            this.form.termAndPrivacyItems = data;
          });
        },
      },
    },
  };
  </script>
  
  <style lang="scss" scoped></style>
  